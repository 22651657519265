.home-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.home-header {
  width: 100%;
  height: 148px;
  display: flex;
  z-index: 100;
  box-shadow: 5px 5px 10px 0px #d4d4d4;
  align-items: center;
  padding-top: var(--dl-space-space-oneandhalfunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-oneandhalfunits);
  background-color: #ffff;
}
.home-navbar-interactive {
  width: 90%;
  height: 108px;
  display: flex;
  position: sticky;
  max-width: var(--dl-size-size-maxwidth);
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-sixunits);
  border-radius: 50px;
  padding-right: var(--dl-space-space-sixunits);
  padding-bottom: var(--dl-space-space-halfunit);
  justify-content: space-between;
  background-color: var(--dl-color-gray-white);
}

.home-link {
  display: contents;
}
.home-image {
  flex: inherit;
  width: var(--dl-size-size-medium);
  height: var(--dl-size-size-medium);
  margin: 0px;
  padding: 0x;
  z-index: 100;
  margin-top: 1px;
  object-fit: cover;
  margin-left: 1px;
  padding-top: 1px;
  border-color: var(--dl-color-gray-black);
  border-width: 0px;
  margin-right: 1px;
  padding-left: 1px;
  margin-bottom: 1px;
  padding-right: 1px;
  padding-bottom: 1px;
  text-decoration: none;
}
.home-desktop-menu {
  flex: 1;
  display: flex;
  justify-content: space-between;
}
.home-links {
  flex: 1;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.home-nav1 {
  font-size: 16px;
  font-style: normal;
  transition: 0.3s;
  font-weight: 700;
  text-decoration: none;
}
.home-nav1:hover {
  color: var(--dl-color-success-300);
}
.home-nav2 {
  font-style: normal;
  transition: 0.3s;
  font-weight: 700;
  margin-left: var(--dl-space-space-twounits);
  text-decoration: none;
}
.home-nav2:hover {
  color: var(--dl-color-success-300);
}
.home-nav3 {
  font-style: normal;
  transition: 0.3s;
  font-weight: 700;
  margin-left: var(--dl-space-space-twounits);
  text-decoration: none;
}
.home-nav3:hover {
  color: var(--dl-color-success-300);
}
.home-nav5 {
  font-style: normal;
  transition: 0.3s;
  font-weight: 700;
  margin-left: var(--dl-space-space-twounits);
  text-decoration: none;
}
.home-nav5:hover {
  color: var(--dl-color-success-300);
}
.home-burger-menu {
  display: none;
}
.home-icon {
  width: var(--dl-size-size-xsmall);
  cursor: pointer;
  height: var(--dl-size-size-xsmall);
}
.home-mobile-menu {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  display: none;
  padding: 32px;
  z-index: 100;
  position: absolute;
  border-radius: 50px;
  flex-direction: column;
  justify-content: space-between;
  background-color: var(--dl-color-gray-white);
}
.home-nav {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-top {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-threeunits);
  justify-content: space-between;
}
.home-logo {
  font-size: 2em;
  font-weight: bold;
}
.home-close-menu {
  display: flex;
  align-items: center;
  justify-content: center;
}
.home-icon02 {
  fill: var(--dl-color-gray-900);
  width: var(--dl-size-size-xsmall);
  cursor: pointer;
  height: var(--dl-size-size-xsmall);
}
.home-links1 {
  flex: 0 0 auto;
  display: flex;
  align-self: flex-start;
  align-items: flex-start;
  flex-direction: column;
}
.home-nav11 {
  color: var(--dl-color-gray-black);
  margin-bottom: var(--dl-space-space-unit);
}
.home-nav21 {
  color: var(--dl-color-gray-black);
  margin-bottom: var(--dl-space-space-unit);
}
.home-nav31 {
  color: var(--dl-color-gray-black);
  margin-bottom: var(--dl-space-space-unit);
}
.home-nav4 {
  color: var(--dl-color-gray-black);
  margin-bottom: var(--dl-space-space-unit);
}
.home-nav51 {
  color: var(--dl-color-gray-black);
  margin-bottom: var(--dl-space-space-unit);
}
.home-buttons {
  display: flex;
  margin-top: var(--dl-space-space-unit);
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.home-login {
  border-color: var(--dl-color-primary1-blue100);
  border-width: 1px;
  margin-right: var(--dl-space-space-twounits);
  padding-left: 1.5rem;
  border-radius: 30px;
  padding-right: 1.5rem;
}
.home-register {
  color: var(--dl-color-gray-white);
  transition: 0.3s;
  border-color: var(--dl-color-primary1-blue100);
  border-width: 1px;
  padding-left: 1.5rem;
  border-radius: 30px;
  padding-right: 1.5rem;
  background-color: var(--dl-color-primary1-blue100);
}
.home-register:hover {
  border-color: #849492ff;
  background-color: #849492ff;
}
.home-icon04 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
  margin-right: var(--dl-space-space-twounits);
}
.home-icon06 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
  margin-right: var(--dl-space-space-twounits);
}
.home-icon08 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.home-hero {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  background-color: var(--dl-color-primary1-blue80);
}
.home-hero1 {
  flex: initial;
  width: 100%;
  display: flex;
  z-index: 100;
  max-width: 100%;
  align-self: flex-start;
  min-height: auto;
  align-items: center;
  padding-top: var(--dl-space-space-sixunits);
  border-color: #ffff;
  border-width: 0px;
  padding-left: var(--dl-space-space-sixunits);
  border-radius: 0px;
  padding-right: SixUnits;
  flex-direction: row;
  padding-bottom: var(--dl-space-space-sixunits);
  justify-content: center;
  background-color: #ffff;
}
.home-container1 {
  gap: var(--dl-space-space-twounits);
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.home-hero-heading {
  color: var(--dl-color-gray-black);
  width: 516px;
  max-width: 800px;
  text-align: center;
  font-family: "Raleway";
  line-height: 1.6;
}
.home-hero-sub-heading {
  color: rgb(0, 0, 0);
  font-size: 18px;
  text-align: center;
  line-height: 1.6;
}
.home-btn-group {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: center;
  flex-direction: row;
  background-color: #ffff;
}
.home-hero-button1 {
  color: var(--dl-color-gray-white);
  font-size: 18px;
  transition: 0.3s;
  font-weight: bold;
  padding-top: 1.5rem;
  border-color: var(--dl-color-primary1-blue100);
  border-width: 0px;
  padding-left: 3rem;
  border-radius: 45px;
  padding-right: 3rem;
  padding-bottom: 1.5rem;
  text-decoration: none;
  background-color: var(--dl-color-primary1-blue100);
}
.home-hero-button1:hover {
  color: var(--dl-color-gray-white);
  border-color: rgba(41, 20, 119, 0.9);
  background-color: var(--dl-color-success-300);
}
.home-lottie-node {
  width: 655px;
  height: 534px;
}
.home-details {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.home-details1 {
  width: 100%;
  height: 742px;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  padding-top: var(--dl-space-space-fourunits);
  padding-left: var(--dl-space-space-sixunits);
  padding-right: var(--dl-space-space-sixunits);
  padding-bottom: var(--dl-space-space-fourunits);
  justify-content: space-between;
}
.home-lottie-node1 {
  width: 314px;
  height: 342px;
}
.home-container2 {
  flex: 1;
  display: flex;
  align-items: flex-start;
  padding-left: var(--dl-space-space-halfunit);
  flex-direction: column;
  justify-content: flex-start;
}
.home-text {
  color: var(--dl-color-primary1-blue80);
  text-align: left;
  font-family: "Raleway";
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.home-details-heading {
  width: 80%;
  text-align: left;
  font-family: "Raleway";
  line-height: 1.6;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.home-details-sub-heading {
  color: var(--dl-color-gray-800);
  font-size: 18px;
  text-align: left;
  line-height: 1.6;
  margin-bottom: var(--dl-space-space-twounits);
}
.home-features {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.home-features-container {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-secondary-grey400);
}
.home-features1 {
  gap: var(--dl-space-space-threeunits);
  width: 100%;
  display: flex;
  max-width: 100%;
  align-self: center;
  align-items: center;
  padding-top: var(--dl-space-space-fourunits);
  border-color: var(--dl-color-gray-black);
  padding-left: var(--dl-space-space-sixunits);
  border-radius: var(--dl-radius-radius-radius4);
  padding-right: var(--dl-space-space-sixunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fourunits);
  justify-content: flex-start;
  background-color: #ffff;
}
.home-container3 {
  border: 2px dashed rgba(120, 120, 120, 0.4);
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.home-text03 {
  color: var(--dl-color-primary1-blue80);
  font-family: "Raleway";
  margin-bottom: 0px;
}
.home-features-heading {
  height: 57px;
  align-self: center;
  font-family: "Raleway";
  border-color: var(--dl-color-gray-black);
  border-width: 0px;
  border-radius: var(--dl-radius-radius-radius4);
  margin-bottom: var(--dl-space-space-halfunit);
  padding-bottom: 0px;
}
.home-container4 {
  width: 100%;
  display: grid;
  grid-gap: var(--dl-space-space-twounits);
  padding-bottom: var(--dl-space-space-sixunits);
  grid-template-columns: 1fr 1fr;
}
.home-faq {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.home-faq-container {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.home-faq1 {
  gap: var(--dl-space-space-twounits);
  flex: auto;
  width: 1172px;
  height: 856px;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  padding-top: 0px;
  padding-left: 00px;
  padding-right: 0;
  flex-direction: row;
  padding-bottom: 0px;
  justify-content: center;
}
.home-container5 {
  border: 2px dashed rgba(120, 120, 120, 0.4);
  display: flex;
  max-width: 35%;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.home-lottie-node2 {
  width: 100vh;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.home-footer {
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-self: center;
  align-items: center;
  padding-top: var(--dl-space-space-fourunits);
  padding-left: var(--dl-space-space-sixunits);
  padding-right: var(--dl-space-space-sixunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fourunits);
  justify-content: center;
}
.home-container6 {
  width: 100%;
  display: grid;
  grid-gap: var(--dl-space-space-twounits);
  align-self: center;
  grid-template-columns: 1fr 1fr;
}
.home-image1 {
  width: 200px;
  object-fit: cover;
}
.home-text09 {
  font-size: large;
  align-self: center;
}
.home-footer1 {
  flex: 0 0 auto;
  width: 100%;
  border: 2px dashed rgba(120, 120, 120, 0.4);
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--dl-color-gray-black);
}
@media(max-width: 991px) {
  .home-hero1 {
    flex-direction: column;
  }
  .home-container1 {
    align-items: center;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-twounits);
    padding-right: 0px;
  }
  .home-hero-heading {
    text-align: center;
  }
  .home-hero-sub-heading {
    text-align: center;
    padding-left: var(--dl-space-space-threeunits);
    padding-right: var(--dl-space-space-threeunits);
  }
  .home-lottie-node {
    width: 100vh;
    height: 100%;
  }
  .home-container2 {
    width: 100%;
    height: 100%;
  }
  .home-details-heading {
    width: 100%;
    height: 100%;
  }
  .home-details-sub-heading {
    width: 100%;
    height: 100%;
    text-align: center;
    padding-left: var(--dl-space-space-threeunits);
    padding-right: var(--dl-space-space-threeunits);
  }
  .home-lottie-node2 {
    width: 100vh;
    hanging-punctuation: 100%;
  }
  .home-footer {
    height: 100%;
    flex-direction: column;
  }
}
@media(max-width: 767px) {
  .home-navbar-interactive {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .home-desktop-menu {
    display: none;
  }
  .home-burger-menu {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .home-mobile-menu {
    padding-top: var(--dl-space-space-oneandhalfunits);
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    padding-bottom: var(--dl-space-space-oneandhalfunits);
  }
  .home-nav11 {
    margin-bottom: var(--dl-space-space-unit);
  }
  .home-nav21 {
    margin-left: 0;
    margin-bottom: var(--dl-space-space-unit);
  }
  .home-nav31 {
    margin-left: 0;
    margin-bottom: var(--dl-space-space-unit);
  }
  .home-nav4 {
    margin-left: 0;
    margin-bottom: var(--dl-space-space-unit);
  }
  .home-nav51 {
    margin-left: 0;
    margin-bottom: var(--dl-space-space-unit);
  }
  .home-hero1 {
    padding-left: 0;
    padding-right: 0;
  }
  .home-hero-sub-heading {
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .home-lottie-node {
    width: 100vh;
    height: 100%;
  }
  .home-details1 {
    align-items: center;
    padding-left: 0;
    padding-right: 0;
    flex-direction: column;
    justify-content: center;
  }
  .home-lottie-node1 {
    width: 100vh;
  }
  .home-container2 {
    align-items: center;
    justify-content: flex-start;
  }
  .home-text {
    text-align: center;
  }
  .home-details-heading {
    text-align: center;
  }
  .home-details-sub-heading {
    text-align: center;
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .home-features1 {
    padding-left: 0;
    padding-right: 0;
  }
  .home-container4 {
    grid-template-columns: 1fr;
  }
  .home-faq1 {
    flex: auto;
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    flex-direction: column;
  }
  .home-container5 {
    max-width: 100%;
    align-self: center;
    align-items: center;
    justify-content: center;
  }
  .home-lottie-node2 {
    width: 100vh;
    align-content: 100%;
  }
  .home-footer {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .home-container6 {
    width: 100%;
    height: 100%;
    grid-template-columns: 1fr;
  }
}
@media(max-width: 479px) {
  .home-navbar-interactive {
    width: 90%;
    padding: var(--dl-space-space-unit);
  }
  .home-mobile-menu {
    padding: 16px;
  }
  .home-logo {
    font-size: 1.5em;
  }
  .home-icon02 {
    fill: var(--dl-color-gray-900);
  }
  .home-hero1 {
    padding-top: var(--dl-space-space-twounits);
    padding-left: 0;
    padding-right: 0;
    padding-bottom: var(--dl-space-space-twounits);
  }
  .home-container1 {
    margin-bottom: var(--dl-space-space-unit);
  }
  .home-btn-group {
    flex-direction: column;
  }
  .home-lottie-node1 {
    width: 100vh;
  }
  .home-features1 {
    padding-top: var(--dl-space-space-twounits);
    padding-left: 0;
    padding-right: 0;
    padding-bottom: var(--dl-space-space-twounits);
  }
  .home-lottie-node2 {
    width: 100vh;
  }
  .home-footer {
    width: 100%;
    height: 100%;
    padding: var(--dl-space-space-unit);
  }
  .home-container6 {
    align-self: center;
    align-items: center;
  }
  .home-image1 {
    width: 50%;
    align-items: center;
  }
  .home-text09 {
    text-align: center;
  }
}
